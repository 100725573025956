const ImageFormatsLg = {
  // portraitSm: { height: 40, aspectRatio: 4 / 5 },
  portraitMd: { height: 60, aspectRatio: 4 / 5 },
  portraitLg: { height: 75, aspectRatio: 4 / 5 },
  // landscapeSm: { height: 24, aspectRatio: 3 / 2 },
  landscapeMd: { height: 65, aspectRatio: 3 / 2 }
};

const ImageFormatsMd = {
  // portraitSm: { height: 40, aspectRatio: 4 / 5 },
  portraitMd: { height: 50, aspectRatio: 4 / 5 },
  portraitLg: { height: 60, aspectRatio: 4 / 5 },
  // landscapeSm: { height: 24, aspectRatio: 3 / 2 },
  landscapeMd: { height: 55, aspectRatio: 3 / 2 }
};

const ImageFormatsSm = {
  // portraitSm: { height: 35, aspectRatio: 4 / 5 },
  portraitMd: { height: 60, aspectRatio: 4 / 5 },
  portraitLg: { height: 75, aspectRatio: 4 / 5 },
  // landscapeSm: { height: 20, aspectRatio: 3 / 2 },
  landscapeMd: { height: 50, aspectRatio: 3 / 2 }
};

const ImageFormatsPortraitSm = {
  // portraitSm: { height: 35, aspectRatio: 4 / 5 },
  portraitMd: { height: 38, aspectRatio: 4 / 5 },
  portraitLg: { height: 44, aspectRatio: 4 / 5 },
  // landscapeSm: { height: 20, aspectRatio: 3 / 2 },
  landscapeMd: { height: 30, aspectRatio: 3 / 2 }
};
const ImageFormatsPortraitMd = {
  // portraitSm: { height: 35, aspectRatio: 4 / 5 },
  portraitMd: { height: 48, aspectRatio: 4 / 5 },
  portraitLg: { height: 55, aspectRatio: 4 / 5 },
  // landscapeSm: { height: 20, aspectRatio: 3 / 2 },
  landscapeMd: { height: 45, aspectRatio: 3 / 2 }
};

export function getImageFormats() {
  if (window.innerWidth < window.innerHeight) {
    if (window.innerWidth < 900) {
      return ImageFormatsPortraitSm;
    } else {
      return ImageFormatsPortraitMd;
    }
  } else if (window.innerWidth < 500) {
    return ImageFormatsSm;
  } else if (window.innerWidth < 1500) {
    return ImageFormatsMd;
  }
  return ImageFormatsLg;
}
