import { getImageFormats } from '~/animation/infinite-floating-boxes/getImageFormats';

export function getBoxesSequence() {
  const ImageFormats = getImageFormats();

  // return [
  //   { ...ImageFormats.portraitLg, row: 1, translateX: 0 }, // 0
  //   { ...ImageFormats.portraitLg, row: 1, translateX: 0 }, // 1
  //   { ...ImageFormats.portraitLg, row: 1, translateX: 0 }, // 2
  //   { ...ImageFormats.portraitLg, row: 1, translateX: 0 }, // 3
  //   { ...ImageFormats.portraitLg, row: 1, translateX: 0 }, // 4
  //   { ...ImageFormats.portraitLg, row: 1, translateX: 0 }, // 5
  //   { ...ImageFormats.portraitLg, row: 1, translateX: 0 }, // 6
  //   { ...ImageFormats.portraitLg, row: 1, translateX: 0 }, // 7
  //   { ...ImageFormats.portraitLg, row: 1, translateX: 0 }, // 8
  //   { ...ImageFormats.portraitLg, row: 1, translateX: 0 } // 9
  // ];

  return [
    { ...ImageFormats.landscapeMd, row: 1, translateX: 0 }, // 0
    { ...ImageFormats.portraitLg, row: 1, translateX: 0 }, // 1
    { ...ImageFormats.portraitMd, row: -1, translateX: 0 }, // 2
    { ...ImageFormats.landscapeMd, row: 1, translateX: 0 }, // 3
    { ...ImageFormats.portraitLg, row: 1, translateX: 0 }, // 4
    { ...ImageFormats.portraitLg, row: 1, translateX: 0 }, // 5
    { ...ImageFormats.landscapeMd, row: -1, translateX: 0 }, // 6
    { ...ImageFormats.portraitMd, row: 1, translateX: 0 }, // 7
    { ...ImageFormats.portraitLg, row: -1, translateX: 0 }, // 8
    { ...ImageFormats.landscapeMd, row: 1, translateX: 5 }, // 9
    { ...ImageFormats.portraitMd, row: 1, translateX: 0 }, // 10
    { ...ImageFormats.portraitLg, row: -1, translateX: 0 } // 11
  ];
}
