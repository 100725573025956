export function getStagger() {
  const staggersLandscape: [number, number][] = [
    [500, 9],
    [1000, 11],
    [1500, 12],
    [2000, 10],
    [2500, 9]
  ];

  const staggersPortrait: [number, number][] = [
    [500, 7],
    [1000, 8],
    [1500, 9],
    [2000, 9],
    [2500, 9]
  ];

  return findStaggerByWindowWidth()[1];

  function findStaggerByWindowWidth() {
    const isPortrait = window.innerWidth < window.innerHeight;

    const staggers = isPortrait ? staggersPortrait : staggersLandscape;

    return (
      staggers.find((it) => {
        return it[0] >= window.innerWidth;
      }) ?? staggers[staggers.length - 1]
    );
  }
}
