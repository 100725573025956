import gsap from 'gsap';
import { getBoxesSequence } from '~/animation/infinite-floating-boxes/getBoxesSequence';
import inputTracker from '~/animation/infinite-floating-boxes/inputTracker';
import { getStagger } from '~/animation/infinite-floating-boxes/getStagger';

type GroupTimeline = {
  state: { tl: gsap.core.Timeline | null };
  create: (group: { elements: HTMLElement[] }, index: number) => void;
  destroy: () => void;
};

export default function infiniteFloatingBoxes() {
  const parameters = {
    wheelSensitivity: 0.0002,
    speed: 0.00006
  };

  const status = {
    progress: 100,
    isPaused: false
  };

  const groups: { elements: HTMLElement[]; width: number; timeline: GroupTimeline }[] = [
    { elements: [], width: 0, timeline: GroupTimeline() },
    { elements: [], width: 0, timeline: GroupTimeline() },
    { elements: [], width: 0, timeline: GroupTimeline() }
  ];

  let timelines: gsap.core.Timeline[] = [];
  const baseDelay = 0.78;
  const delays = [baseDelay, baseDelay + 0.33333, baseDelay + 0.6666];
  // const delays = [0, 0, 0];

  return {
    play,
    pause,
    init
  };

  function init(boxes: HTMLElement[]) {
    const InputTracker = inputTracker({ lerpFactor: 0.1 });

    /* init */
    boxes.forEach(function (box, index) {
      const mod = index % groups.length;
      const group = groups[mod];

      const BoxesSequence = getBoxesSequence();
      const format = BoxesSequence[index % BoxesSequence.length];

      const elementWidth = box.getAttribute('data-width');
      const elementHeight = box.getAttribute('data-height');
      const elementTop = box.getAttribute('data-top');

      // if (mod === 1) {
      //   box.style.marginLeft = `-50vw`;
      // }

      box.style.width = elementWidth
        ? `${elementWidth}`
        : `${format.height * format.aspectRatio}svh`;

      box.style.height = elementHeight ? `${elementHeight}` : `${format.height}svh`;

      if (elementTop) {
        box.style.top = elementTop;
      } else if (format.row < 0) {
        box.style.bottom = `${-10 * format.row}svh`;
      } else {
        box.style.top = `${10 * format.row}svh`;
      }

      if (format.row < 3) {
        box.setAttribute('data-transition', 'up');
      } else {
        box.setAttribute('data-transition', 'down');
      }

      if (format.translateX) {
        box.setAttribute('data-translate-x', String(format.translateX));
      }

      box.setAttribute('data-group-index', String(index % groups.length));
      box.setAttribute('data-sequence-index', String(index % BoxesSequence.length));

      group.elements.push(box);
      // group.width += box.clientWidth;
    });

    /* events */
    boxes.forEach(function (box) {
      box.addEventListener('click', () => onBoxClick(box));
    });

    /* animation */
    groups.forEach(function (g, i) {
      g.timeline.create(g, i);

      if (g.timeline.state.tl) {
        timelines.push(g.timeline.state.tl);
      }
    });

    gsap.ticker.add(function () {
      if (status.isPaused) {
        return;
      }
      const currentSpeed = InputTracker.getCurrentSpeed();

      status.progress += currentSpeed.preferred * 0.02;
      status.progress += parameters.speed;

      timelines.forEach(function (tl, index) {
        tl.progress(status.progress + delays[index]);
      });
    });

    useOnWindowFinishResize(recreateGroupTimelines);
  }

  function recreateGroupTimelines() {
    timelines = [];

    groups.forEach(function (g, i) {
      g.timeline.destroy();
      g.timeline.create(g, i);

      if (g.timeline.state.tl) {
        timelines.push(g.timeline.state.tl);
      }
    });
  }

  function GroupTimeline(): GroupTimeline {
    const state: {
      tl: gsap.core.Timeline | null;
    } = {
      tl: null
    };

    return { state, create, destroy };

    function create(group: { elements: HTMLElement[] }, index: number) {
      state.tl = getBaseTimeline();
      state.tl.progress(index / groups.length, true);

      // const delay = 0;
      const delay = (index - 1) * 1.25;

      state.tl.fromTo(
        group.elements,
        {
          x: (i) => {
            return getTranslationWidth(
              group.elements[i],
              Math.max(window.innerHeight, window.innerWidth) + 10
            );
          }
        },
        {
          x: (i) => {
            return getTranslationWidth(
              group.elements[i],
              Math.max(window.innerHeight, window.innerWidth) * -1
            );
          },
          stagger: getStagger(), // change stagger to window with -> higher value on smaller screens
          // stagger: getStagger(), // change stagger to window with -> higher value on smaller screens
          ease: 'none',
          delay: delay,
          duration: 10 - delay
        }
      );
    }

    function destroy() {
      if (state.tl) {
        state.tl.kill();

        const targets: Element[] = [];
        state.tl.getChildren().forEach((tween) => {
          return targets.push(...(tween.targets() ?? []));
        });

        targets.forEach((node) => {
          gsap.killTweensOf(node);
          gsap.set(node, { clearProps: 'x' });
        });
      }

      state.tl = null;
    }
  }

  function play() {
    status.isPaused = false;
  }

  function pause() {
    status.isPaused = true;
  }

  function onBoxClick(box: HTMLElement) {
    box.classList.add('-in-transition');

    pause();
  }

  function getTranslationWidth(box: HTMLElement, baseWidth: number) {
    const tX = box.getAttribute('data-translate-x');
    let t = baseWidth;

    if (tX) {
      t += (Number(tX) ?? 0) * window.innerWidth * 0.01;
    }

    return t;
  }

  function getBaseTimeline() {
    return gsap.timeline({
      repeat: -1,
      paused: true,
      ease: 'none'
    });
  }
}

function useOnWindowFinishResize(cb: () => void) {
  let t: number | undefined = undefined;

  window.addEventListener('resize', function () {
    clearTimeout(t);
    // @ts-ignore
    t = setTimeout(cb, 500);
  });
}
