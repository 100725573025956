<template>
  <div class="floating-projects" :class="{ '-animating': isAnimating }" data-floating-container>
    <template v-for="(p, pIndex) in reorderedProjects">
      <h1
        v-if="title && pIndex === 1"
        data-width="20ch"
        data-height="100%"
        data-top="0"
        class="title object"
        data-floating-object
        data-transition="down"
      >
        {{ title }}
      </h1>
      <p
        v-if="cta && pIndex === 6"
        data-width="25ch"
        data-height="100%"
        data-top="0"
        class="text object"
        data-floating-object
        data-transition="up"
      >
        {{ cta }}
      </p>
      <div data-floating-object data-transition="up" class="object" :data-index="pIndex">
        <NuxtLink class="link" :to="`/projekte/${p.data.slug}`">
          <DatoAsset
            fade-in="immediate"
            class="asset -hover-enabled"
            v-if="p.data.image"
            :asset="p.data.image"
            :asset-vs="p.data.smallImage ?? undefined"
            :hide-copyright="true"
          />
          <div class="asset placeholder" v-else></div>
          <p class="label">
            <b class="title">{{ p.data.shortTitle }}</b>
            <span class="divider"></span>
            <span class="service">{{ p.data.service?.title }}</span>
          </p>
        </NuxtLink>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import infiniteFloatingBoxes from '~/animation/infiniteFloatingBoxes';
import type { ProjectFragment } from '#gql';
import DatoAsset from '~/dato/DatoAsset.vue';

const props = defineProps<{
  data: ProjectFragment[];
  title: string;
  cta: string;
}>();

const projectInTransition = ref<null | string>(null);
const isAnimating = ref<boolean>(false);

const floatingBoxes = infiniteFloatingBoxes();
const reorderedProjects = sortProjects();

function sortProjects() {
  const order = [3, 6, 5, 2, 9, 8, 1, 0, 7, 4];

  const list = props.data.map((data, index) => {
    return {
      index: index,
      data
    };
  });

  if (list.length === order.length) {
    return list.sort(function (a, b) {
      return order.indexOf(a.index) - order.indexOf(b.index);
    });
  }

  return list;
}

onMounted(function () {
  const objects = Array.from(document.querySelectorAll('[data-floating-object]')) as HTMLElement[];

  floatingBoxes.init(objects);
  isAnimating.value = true;
});
</script>

<style scoped lang="scss">
.floating-projects {
  width: 100vw;
  height: 100svh;
  overflow: clip;

  &.-animating {
    visibility: visible;
  }

  &:not(.-animating) {
    visibility: hidden;
  }

  > .object {
    position: absolute;
    display: block;

    //&::after {
    //  @include text-xs(700);
    //
    //  content: attr(data-sequence-index);
    //  position: absolute;
    //  z-index: 10;
    //  top: 1rem;
    //  left: 1rem;
    //  color: white;
    //}

    //
    //&::before {
    //  @include text-xs(700);
    //
    //  content: attr(data-group-index);
    //  position: absolute;
    //  z-index: 10;
    //  top: 1rem;
    //  right: 1rem;
    //}

    &:hover {
      > .link {
        > .label {
          transform: translateY(5px);
        }
      }
    }

    > .link {
      text-decoration: none;

      > .asset {
        --background-color: var(--color-off-white) !important;

        width: 100%;
        height: calc(100% - 2rem);

        > :deep(.image) {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      > .placeholder {
        width: 100%;
        height: calc(100% - 2rem);
        background-color: var(--color-grey);
      }

      > .label {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        padding: 0.5rem 0 0 0;
        transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);

        > .title {
          @include text-xs(700);

          overflow-x: clip;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 100%;
        }

        > .divider {
          width: 1.5rem;
          height: 1px;
          background-color: var(--color-black);
          display: inline-block;
        }

        > .service {
          @include text-xs(300);

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  > .text,
  > .title {
    display: flex;
    z-index: 2;
    pointer-events: none;
  }

  > .text {
    align-items: center;
    padding: 0 2ch;

    @media (--vs) {
      @include text-xl(700);
    }

    @media (--vl) {
      @include text-3xl(700);

      font-size: 3.5rem !important;
    }
  }

  > .title {
    align-items: flex-end;
    padding: 10lvh 0;
    white-space: nowrap;

    @media (--vs) {
      @include text-2xl(700);
    }

    @media (--vl) {
      @include text-4xl(700);
    }
  }
}
</style>
