<template>
  <div class="index-page" :data-horizontal-layout="isHorizontalLayout">
    <main class="main horizontal-layout" v-if="isHorizontalLayout">
      <FloatingProjects
        :title="floatingHeader ?? ''"
        :cta="floatingCta ?? ''"
        v-if="floatingProjects"
        :data="floatingProjects"
      />
    </main>
    <main class="main default-layout" v-if="isHorizontalLayout === false">
      <component
        v-if="page?.headerModule"
        :key="page.headerModule.id"
        :is="HeaderModules[page.headerModule.__typename]"
        :data="page.headerModule"
      ></component>
      <component
        v-for="module in page?.contentModules"
        :key="module.id"
        :is="ContentModules[module.__typename]"
        :data="module"
      ></component>
    </main>
    <SiteFooter theme="light" v-if="isHorizontalLayout === false" />
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import FloatingProjects from '~/components/home/FloatingProjects.vue';
import { type SeoMetaTagType, toHead } from 'vue-datocms';
import type { GetPageBySlugQuery } from '#gql';
import type { ProjectFragment } from '#gql';
import { HeaderModules } from '~/components/header-modules/HeaderModules';
import { ContentModules } from '~/components/content-modules/ContentModules';

const { data, error } = await useAsyncGql({
  operation: 'GetPageBySlug',
  variables: { slug: 'home' }
});

const page = (data.value as GetPageBySlugQuery).page;

const isHorizontalLayout = computed(function () {
  return page?.layout === 'horizontal';
});

const floatingProjects = computed(function () {
  if (!page) {
    return null;
  } else if (!isHorizontalLayout.value) {
    return null;
  }

  return Array.from(page?.floatingProjects) as ProjectFragment[];
});

const floatingHeader = computed(function () {
  if (!page) {
    return null;
  } else if (!isHorizontalLayout.value) {
    return null;
  }

  return page.floatingHeader ?? '';
});

const floatingCta = computed(function () {
  if (!page) {
    return null;
  } else if (!isHorizontalLayout.value) {
    return null;
  }

  return page.floatingCta ?? '';
});

useHead(toHead(page?.seo as SeoMetaTagType[]));

definePageMeta({
  pageTransition: {
    name: 'from-index',
    mode: 'out-in',
    onLeave: async function (el, done) {
      await useSleep(100);

      const isHorizontalLayout = el.getAttribute('data-horizontal-layout') === 'true';

      const tl = gsap.timeline({
        onComplete: function () {
          done();
        }
      });

      if (!isHorizontalLayout) {
        tl.to(el, {
          opacity: 0
        });
      } else {
        const transitionElementsDown = document.querySelectorAll(
          "[data-transition='down']:not(.-in-transition)"
        );
        const transitionElementsUp = document.querySelectorAll(
          "[data-transition='up']:not(.-in-transition)"
        );
        const elementInTransition = document.querySelector('.-in-transition');

        tl.to(transitionElementsDown, {
          y: window.innerHeight,
          duration: 0.5,
          ease: 'power4.in'
        })
          .to(
            transitionElementsUp,
            {
              y: window.innerHeight * -1,
              duration: 0.5,
              ease: 'power4.in'
            },
            '<'
          )
          .to(
            elementInTransition,
            {
              y: 0
            },
            '<'
          );
      }
    }
  }
});
</script>

<style scoped lang="scss"></style>
